import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intersection } from 'lodash';
import Checkbox from './checkbox';
import LineItemPromoProductStateTaxExemptionEditor from './line-item-promo-product-state-tax-exemption-editor';
import Select from 'react-select';

const GlobalData = window.GlobalData;

class LineItemPromoProductFields extends Component {
  static defaultProps = {
    promo_product: {
      name: '',
      per_piece_cost: null,
      shipping_cost: null,
      setup_cost: null,
      color: null,
      supplier: null,
      category: null,
    },
    quantity: null,
    bird_bank: false,
    bag_tag: false,
    individual_ship: false,
    individual_payment: false,
    areSizeQuantitiesSet: false,
    onChange: () => {},
  };

  static propTypes = {
    promo_product: PropTypes.object,
    index: PropTypes.number.isRequired,
    quantity: PropTypes.number,
    bird_bank: PropTypes.bool.isRequired,
    bag_tag: PropTypes.bool.isRequired,
    individual_ship: PropTypes.bool.isRequired,
    individual_payment: PropTypes.bool.isRequired,
    free_items: PropTypes.number,
    areSizeQuantitiesSet: PropTypes.bool,
    prefix: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    stateSalesTaxExemptionEditingEnabled: PropTypes.bool,
  };

  onUpdate(name, valProp, event) {
    let { line_item } = this.props;
    let p = {};
    p[name] = event.target[valProp || 'value'];
    let updatedItem = { ...line_item, ...p };
    this.props.onChange(this.props.index, updatedItem);
  }

  onUpdatePromoProduct(name, event) {
    let { promo_product } = this.props.line_item;
    let p = {};

    if (name === 'category') {
      p[name] = event;
    } else {
      p[name] = event.target.value;
    }
    let newPromoProduct = { ...promo_product, ...p };
    let newLineItem = {
      ...this.props.line_item,
      promo_product: newPromoProduct,
    };
    this.props.onChange(this.props.index, newLineItem);
  }

  canEditPromoProducts() {
    return (
      intersection(GlobalData.currentUserRoles, [
        'uberadmin',
        'admin',
        'business_development_leader',
        'account_executive',
      ]).length > 0
    );
  }

  render() {
    let { index, prefix, line_item, areSizeQuantitiesSet } = this.props;

    let {
      promo_product,
      quantity,
      free_items,
      bird_bank,
      bag_tag,
      individual_ship,
      individual_payment,
    } = line_item;

    let {
      name,
      per_piece_cost,
      shipping_cost,
      setup_cost,
      actual_per_piece_cost,
      actual_setup_cost,
      actual_shipping_cost,
      color,
      supplier,
      promo_product_exemption_eligibilities,
      category,
    } = promo_product;

    let fieldName = n => `${prefix}[${n}]`;
    let promoFieldName = n => `${fieldName('promo_product_attributes')}[${n}]`;

    if (this.canEditPromoProducts()) {
      return (
        <div className="form-full form-vertical">
          <fieldset>
            <input type="hidden" name={fieldName('id')} value={line_item.id} />

            <input
              type="hidden"
              name={promoFieldName('id')}
              value={promo_product.id}
            />

            <div>
              <label htmlFor={`name-${index}`}>Name</label>
              <input
                type="text"
                id={`name-${index}`}
                name={promoFieldName('name')}
                value={name}
                onChange={this.onUpdatePromoProduct.bind(this, 'name')}
                placeholder="Name"
              />
            </div>

            <div>
              <label htmlFor={`per_piece_cost-${index}`}>
                Net Per Piece Cost
              </label>
              <input
                type="number"
                id={`per_piece_cost-${index}`}
                name={promoFieldName('per_piece_cost')}
                value={per_piece_cost}
                step="any"
                placeholder="Net Per Piece Cost"
                onChange={this.onUpdatePromoProduct.bind(
                  this,
                  'per_piece_cost'
                )}
              />
            </div>

            <div>
              <label htmlFor={`shipping_cost-${index}`}>Shipping Cost</label>
              <input
                type="number"
                id={`shipping_cost-${index}`}
                name={promoFieldName('shipping_cost')}
                value={shipping_cost}
                step="any"
                placeholder="Shipping Cost"
                onChange={this.onUpdatePromoProduct.bind(this, 'shipping_cost')}
              />
            </div>

            <div>
              <label htmlFor={`supplier-${index}`}>Supplier</label>
              <input
                type="text"
                id={`supplier-${index}`}
                name={promoFieldName('supplier')}
                value={supplier}
                step="any"
                placeholder="Supplier"
                onChange={this.onUpdatePromoProduct.bind(this, 'supplier')}
              />
            </div>

            <div>
              <label htmlFor={`"setup_cost-${index}`}>Setup Cost</label>
              <input
                type="number"
                id={`setup_cost-${index}`}
                name={promoFieldName('setup_cost')}
                value={setup_cost}
                step="any"
                placeholder="Setup Cost"
                onChange={this.onUpdatePromoProduct.bind(this, 'setup_cost')}
              />
            </div>

            <input
              type="hidden"
              name={promoFieldName('category')}
              value={category || ''}
            />

            <div>
              <label htmlFor="category" className="mbs">
                Category
              </label>
              <Select
                options={GlobalData.categories}
                value={this.props.line_item.promo_product.category || ''}
                onChange={event => this.onUpdatePromoProduct('category', event)}
                matchProp="label"
                simpleValue={true}
                placeholder="Select a Category"
              />
            </div>

            <div>
              <label htmlFor={`"color-${index}`}>Color</label>
              <input
                type="text"
                id={`color-${index}`}
                name={promoFieldName('color')}
                value={color}
                placeholder="Color"
                onChange={this.onUpdatePromoProduct.bind(this, 'color')}
              />
            </div>

            {areSizeQuantitiesSet && (
              <small className="txt-muted">
                <em>
                  Size quantities have already been set. If you want to update
                  the quantity, update the size breakdown instead.
                </em>
              </small>
            )}

            <div>
              <label htmlFor={`quantity-${index}`}>Quantity</label>
              <input
                type="number"
                id={`quantity-${index}`}
                name={fieldName('quantity')}
                value={quantity}
                step="1"
                disabled={areSizeQuantitiesSet ? 'disabled' : ''}
                className={areSizeQuantitiesSet ? 'readonly' : ''}
                onChange={this.onUpdate.bind(this, 'quantity', 'value')}
                placeholder="Quantity"
              />
            </div>

            {GlobalData.canAddFreeItems && (
              <div>
                <label htmlFor={`free-items-${index}`}>
                  Additional Free Items
                </label>
                <input
                  type="number"
                  id={`free-items-${index}`}
                  name={fieldName('free_items')}
                  value={free_items}
                  step="1"
                  min="0"
                  onChange={this.onUpdate.bind(this, 'free_items', 'value')}
                  placeholder="Additional Free Items"
                />
              </div>
            )}

            <label>
              <Checkbox
                name={fieldName('bird_bank')}
                checked={bird_bank}
                onChange={this.onUpdate.bind(this, 'bird_bank', 'checked')}
              />{' '}
              Bird Bank
            </label>

            {bird_bank ? (
              <label>
                <Checkbox
                  name={fieldName('individual_payment')}
                  checked={bird_bank && individual_payment !== false}
                  className="mll"
                  onChange={this.onUpdate.bind(
                    this,
                    'individual_payment',
                    'checked'
                  )}
                />{' '}
                Individual Payment
              </label>
            ) : (
              <input
                type="hidden"
                name={this.props.prefix + '[individual_payment]'}
                value="0"
              />
            )}

            <label>
              <Checkbox
                name={fieldName('bag_tag')}
                checked={bag_tag}
                onChange={this.onUpdate.bind(this, 'bag_tag', 'checked')}
              />{' '}
              Bag &amp; Tag
            </label>

            <label>
              <Checkbox
                name={fieldName('individual_ship')}
                checked={individual_ship}
                onChange={this.onUpdate.bind(
                  this,
                  'individual_ship',
                  'checked'
                )}
              />{' '}
              Individual Shipping
            </label>
          </fieldset>
          <LineItemPromoProductStateTaxExemptionEditor
            prefix={fieldName('promo_product_attributes')}
            exemptionEligibilities={promo_product_exemption_eligibilities}
          />

          {GlobalData.canEditFinancials && (
            <div>
              <h3 className="mbs">Financial Options:</h3>

              <div>
                <label htmlFor={`"actual_per_piece_cost-${index}`}>
                  Actual Net Per Piece Cost
                </label>
                <input
                  type="number"
                  id={`actual_per_piece_cost-${index}`}
                  name={promoFieldName('actual_per_piece_cost')}
                  value={actual_per_piece_cost || ''}
                  step="any"
                  placeholder="Actual Net Per Piece Cost"
                  onChange={this.onUpdatePromoProduct.bind(
                    this,
                    'actual_per_piece_cost'
                  )}
                />
              </div>

              <div>
                <label htmlFor={`"actual_shipping_cost-${index}`}>
                  Actual Shipping
                </label>
                <input
                  type="number"
                  id={`actual_shipping_cost-${index}`}
                  name={promoFieldName('actual_shipping_cost')}
                  value={actual_shipping_cost || ''}
                  step="any"
                  placeholder="Actual Shipping"
                  onChange={this.onUpdatePromoProduct.bind(
                    this,
                    'actual_shipping_cost'
                  )}
                />
              </div>

              <div>
                <label htmlFor={`"actual_setup_cost-${index}`}>
                  Actual Setup
                </label>
                <input
                  type="number"
                  id={`actual_setup_cost-${index}`}
                  name={promoFieldName('actual_setup_cost')}
                  value={actual_setup_cost || ''}
                  step="any"
                  placeholder="Actual Setup"
                  onChange={this.onUpdatePromoProduct.bind(
                    this,
                    'actual_setup_cost'
                  )}
                />
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="form-full form-vertical">
          <fieldset>
            <input type="hidden" name={fieldName('id')} value={line_item.id} />

            <input
              type="hidden"
              name={promoFieldName('id')}
              value={promo_product.id}
            />

            <input
              type="hidden"
              name={promoFieldName('per_piece_cost')}
              value={
                promo_product.per_piece_cost ? promo_product.per_piece_cost : 0
              }
            />

            <input
              type="hidden"
              name={promoFieldName('shipping_cost')}
              value={
                promo_product.shipping_cost ? promo_product.shipping_cost : 0
              }
            />

            <input
              type="hidden"
              name={promoFieldName('setup_cost')}
              value={promo_product.setup_cost ? promo_product.setup_cost : 0}
            />

            <div>
              <label htmlFor={`name-${index}`}>Name</label>
              <input
                type="text"
                id={`name-${index}`}
                name={promoFieldName('name')}
                value={name}
                onChange={this.onUpdatePromoProduct.bind(this, 'name')}
                placeholder="Name"
              />
            </div>

            <div>
              <label htmlFor={`supplier-${index}`}>Supplier</label>
              <input
                type="text"
                id={`supplier-${index}`}
                name={promoFieldName('supplier')}
                value={supplier}
                step="any"
                placeholder="Supplier"
                onChange={this.onUpdatePromoProduct.bind(this, 'supplier')}
              />
            </div>

            <div>
              <label htmlFor={`quantity-${index}`}>Quantity</label>
              <input
                type="number"
                id={`quantity-${index}`}
                name={fieldName('quantity')}
                value={quantity}
                step="1"
                onChange={this.onUpdate.bind(this, 'quantity', 'value')}
                placeholder="Quantity"
              />
            </div>

            {GlobalData.canAddFreeItems && (
              <div>
                <label htmlFor={`free-items-${index}`}>
                  Additional Free Items
                </label>
                <input
                  type="number"
                  id={`free-items-${index}`}
                  name={fieldName('free_items')}
                  value={free_items}
                  step="1"
                  min="0"
                  onChange={this.onUpdate.bind(this, 'free_items', 'value')}
                  placeholder="Additional Free Items"
                />
              </div>
            )}

            <div>
              <label htmlFor={`"color-${index}`}>Color</label>
              <input
                type="text"
                id={`color-${index}`}
                name={promoFieldName('color')}
                value={color}
                placeholder="Color"
                onChange={this.onUpdatePromoProduct.bind(this, 'color')}
              />
            </div>

            <label>
              <Checkbox
                name={fieldName('bird_bank')}
                checked={bird_bank}
                onChange={this.onUpdate.bind(this, 'bird_bank', 'checked')}
              />{' '}
              Bird Bank?
            </label>

            {bird_bank ? (
              <label>
                <Checkbox
                  name={fieldName('individual_payment')}
                  checked={individual_payment}
                  className="mll"
                  onChange={this.onUpdate.bind(
                    this,
                    'individual_payment',
                    'checked'
                  )}
                />{' '}
                Individual Payment
              </label>
            ) : null}

            <label>
              <Checkbox
                name={fieldName('bag_tag')}
                checked={bag_tag}
                onChange={this.onUpdate.bind(this, 'bag_tag', 'checked')}
              />{' '}
              Bag &amp; Tag?
            </label>

            <label>
              <Checkbox
                name={fieldName('individual_ship')}
                checked={individual_ship}
                onChange={this.onUpdate.bind(
                  this,
                  'individual_ship',
                  'checked'
                )}
              />{' '}
              Individual Shipping?
            </label>
          </fieldset>
        </div>
      );
    }
  }
}

export default LineItemPromoProductFields;
